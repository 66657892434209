<template>
  <el-container>
    <div :style="'margin: 10px ' + (paddingLeft ? paddingLeft : 100) + 'px 10px 0px; width: 100%;'">
      <ul id="descs" v-if="showWhat ==='all' || showWhat ==='line12' || showWhat ==='line1'">
          <li>
            <span>少数民族</span>
            <span class="case_view color_minzu_1"></span>
          </li>
          <li>
            <span>中原女性</span>
            <span class="case_view color_gender_0"></span>
          </li>
          <li>
            <span>中原男性</span>
            <span class="case_view color_minzu_hua"></span>
          </li>
      </ul>
      <ul id="descs" v-if="showWhat ==='all' || showWhat ==='line12' || showWhat ==='line2'">
          <li v-if="page_name != 'Gratitude'">鸣谢</li>
          <li v-if="page_name != 'Gratitude'"><router-link :to="{ name: 'Gratitude', hash: null }">学者</router-link></li>
          <li v-if="page_name != 'Gratitude'"><router-link :to="{ name: 'Gratitude', hash: '#upper' }">up主</router-link></li>
          <!-- <li><a href="">app下载</a></li> -->
          <!-- <li><span @click="go_to('About', '')">关于我们</span></li> -->
          <li v-if="page_name != 'About'"><router-link :to="{ name: 'About', hash: null }">关于我们</router-link></li>
          <li v-if="page_name != 'About'"><router-link :to="{ name: 'About', hash: '#explain' }">使用说明</router-link></li>
          <li v-if="page_name != 'About'"><router-link :to="{ name: 'About', hash: '#contact' }">联系我们</router-link></li>
      </ul>
      <ul v-if="niming && showWhat ==='all'">
        <li><router-link  :to="{ name: 'Agreement', query:{type:'user'}}">用户协议</router-link></li>
        <li><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备20026180号-10</a></li>
        <li>泰安道听途想网络科技有限公司</li>
        <li>历史信息查询工具</li>
        <!-- <li>
          <img style="width:0.15rem;high:0.15rem; margin-right:0.01rem;" src="@/assets/img/gongan.png"  alt="">
          <span>idxxxxxx</span>
        </li> -->
      </ul>
    </div>
  </el-container>
</template>
<script>
import Utils from '@/utils/utils'
import axios from 'axios'
// import router from '@/router/index.js'

export default {
  name: 'FooterView',
  props: {
    page_name: String,
    show_what: String,
    padding_left: Number
  },
  data () {
    return {
      niming: false,
      showWhat: this.show_what,
      paddingLeft: this.padding_left
    }
  },
  mounted () {
    this.init_x()
  },
  methods: {
    init_x () {
      this.niming = Utils.check_is_niming()
    },
    // linkTo() {
    //   if (this.$route.hash) {
    //     return this.$route.path + this.$route.hash;
    //   } else {
    //     return this.$route.path;
    //   }
    // },
    go_to (e, hash) {
      // if (hash === '') {
      //   router.push({ name: e })
      //   return
      // }
      // router.push({ name: e, hash: hash })
      // if (e === this.current_index) {
      //   return
      // }
      // if (e === 0) {
      //   router.push({ name: 'Person_index', query: {} })
      // } else if (e === 1) {
      //   router.push({ name: 'Person_search', query: {} })
      // } else if (e === 2) {
      //   router.push({ name: 'Power_index', query: {} })
      // }
    },
    add_idea (e) {
      axios.post('/add_idea/', { content: this.content })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.content = ''
          Utils.alert_success('添加成功')
        })
    }
  }
}
</script>

<style scoped>
ul{
  text-align: right;
}
ul li{
  display: inline-block;
  margin: 10px;
  color: #bbb;
}
ul li a{
  color: rgb(157, 212, 230);
}
.color_gray{
  background: #bbb;
}
.color_caotou{
  background: #00BFFF;
}
.color_zhengti{
  background: #F08080;
}
.color_baotu{
  background: #48D1CC;
}
.shapes{
  display: inline-block;
  margin: 10px 20px;
  padding: 0px 40px 0px 40px;
  height: 600px;
  overflow: scroll;
}
.shape_div{
  margin: 6px 0px;
  padding: 6px;
  width: 450px;
}
.shape_id{
  margin: 4px 10px;
  font-size: 12px;
  color: lightgreen;
}
.case_view{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.color_minzu_hua{
  background-color: #50b7c1;
}

.color_gender_0{
  background-color: pink;
}
.color_minzu_1{
  background-color: #de8af8;
}
#descs li span{
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
}
</style>
